// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/plus/home_bg.png";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { getFileByName } from "controllers/FilesController";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

function PageContentComponent() {
  const { id } = useParams();
  const [files, setFiles] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const getFileContent = async () => {
      try {
        const data = await getFileByName(id);
        setFiles(data);

        const bytes = atob(data[0].file);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }
        const blob = new Blob([out], { type: "application/pdf" });

        // Crear una URL temporal para el Blob
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        console.error(error);
      }
    };
    getFileContent();
  }, [id]);
  return (
    <>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "overlay",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKTypography
            variant="h1"
            color="white"
            textAlign="center"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["xl"],
              },
            })}
          >
            {id.charAt(0).toUpperCase() + id.replace(/-/g, " ").slice(1).toLowerCase()}
          </MKTypography>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Container sx={{ mt: 2 }}>
            <MKTypography variant="h6" mb={6} color={"dark"}>
              {files ? (
                <>
                  <div dangerouslySetInnerHTML={{ __html: files[0].description }} />
                </>
              ) : (
                ""
              )}
            </MKTypography>

            <div
              style={{
                height: "70vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {pdfUrl ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
                  </div>
                ) : (
                  <p>Cargando el archivo PDF...</p>
                )}
              </Worker>
            </div>
          </Container>
        </Card>
      </MKBox>
    </>
  );
}

export default PageContentComponent;
