// @mui material components
import {
  AvTimer,
  Check,
  Handshake,
  Looks3,
  Looks4,
  LooksOne,
  LooksTwo,
  PeopleAlt,
  ThumbUpAlt,
  WorkspacePremium,
} from "@mui/icons-material";
import { Box, Icon, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid container justifyContent="flex-start" width={"100%"}>
            <Grid item xs={12} md={6}>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon="radar"
                  title="Misión"
                  description="Conectamos al mundo a familias y empresas a través de servicios de telecomunicaciones innovadores y de calidad."
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon="remove_red_eye"
                  title="Visión 2026"
                  description="Ser la empresa referente de excelencia en atención al cliente, a través de la estandarización, automatización de procesos y protección de la información, con el fin de mejorar las expectativas de nuestras partes interesadas."
                />
              </MKBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MKBox mb={{ xs: 5, md: 5 }} lineHeight={1} p={2} textAlign={"center"}>
                <MKTypography
                  color={"info"}
                  display="block"
                  variant="5"
                  fontWeight="bold"
                  mt={1}
                  mb={1.5}
                  sx={{
                    textAlign: "center",
                    position: "relative",
                    pb: 2,
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "2px",
                      background:
                        "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)",
                    },
                  }}
                >
                  {<Icon>workspace_premium</Icon>} Nuestra Política de Calidad
                </MKTypography>
                <Box
                  sx={{ textAlign: "center", px: 2 }}
                  display="block"
                  variant={"body2"}
                  color="text"
                >
                  <Typography variant="body2">
                    <b style={{ color: "rgb(26, 115, 232)" }}>
                      AIRMAXTELECOM SOLUCIONES TECNOLÓGICAS S.A.
                    </b>{" "}
                    es una empresa dedicada a la prestación de servicios de telecomunicaciones y que
                    a través de la implementación de la Norma
                    <b style={{ color: "rgb(26, 115, 232)" }}> ISO 9001:2015</b> atendemos los
                    requerimientos, necesidades de los clientes y normativa legal aplicable.
                  </Typography>
                  <Typography variant="body2">
                    Trabajamos de manera incansable y con procesos de mejora continua para
                    proporcionar a nuestros clientes conectividad rápida, confiable y segura que les
                    permita mejorar y facilitar su vida. Comunicamos la política de calidad a todas
                    las partes interesadas.
                  </Typography>
                </Box>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={12} width={"100%"}>
              <MKBox mb={{ xs: 5, md: 5 }} width={"100%"} lineHeight={1} p={2} textAlign={"center"}>
                <MKTypography
                  color={"info"}
                  display="block"
                  variant="5"
                  fontWeight="bold"
                  mt={1}
                  mb={1.5}
                  sx={{
                    textAlign: "center",
                    position: "relative",
                    pb: 2,
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "2px",
                      background:
                        "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)",
                    },
                  }}
                >
                  {<Icon>home_work</Icon>} Nuestros valores institucionales
                </MKTypography>
                <Box sx={{ textAlign: "left", px: 2 }} display="block" color="text">
                  <Grid
                    container
                    justifyContent="flex-start"
                    width={"100%"}
                    sx={{ textAlign: { xs: "justify", md: "start" } }}
                  >
                    <Grid item xs={12} md={6}>
                      <Box
                        display={"flex"}
                        alignItems={"flex-start"}
                        sx={{ flexDirection: { xs: "column", md: "row" }, m: 1 }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                          mr={1}
                        >
                          <PeopleAlt sx={{ mr: 1 }}> </PeopleAlt>Servicio:
                        </Typography>
                        <Typography color="info" variant="body2">
                          Mantenemos un alto sentido de colaboración hacia lo demás.
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"flex-start"}
                        sx={{ flexDirection: { xs: "column", md: "row" }, m: 1 }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                          mr={1}
                        >
                          <AvTimer sx={{ mr: 1 }}></AvTimer> Disciplina:
                        </Typography>
                        <Typography color="info" variant="body2">
                          El hábito que en la empresa generamos en base al compromiso y autocontrol.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ textAlign: { xs: "justify", md: "start" } }}>
                      <Box
                        variant="body2"
                        display={"flex"}
                        sx={{ flexDirection: { xs: "column", md: "row" }, m: 1 }}
                        flexDirection={"row"}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                          mr={1}
                        >
                          <ThumbUpAlt sx={{ mr: 1 }}></ThumbUpAlt> Integridad:
                        </Typography>
                        <Typography color="info" variant="body2">
                          Actuamos en base a nuestros principios.
                        </Typography>
                      </Box>
                      <Box
                        variant="body2"
                        display={"flex"}
                        sx={{ flexDirection: { xs: "column", md: "row" }, m: 1 }}
                        flexDirection={"row"}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                          mr={1}
                        >
                          <WorkspacePremium sx={{ mr: 1 }}></WorkspacePremium> Calidad:
                        </Typography>
                        <Typography color="info" variant="body2">
                          Trabajamos para generar un servicio de mayor valor.
                        </Typography>
                      </Box>
                      <Box
                        variant="body2"
                        display={"flex"}
                        sx={{ flexDirection: { xs: "column", md: "row" }, m: 1 }}
                        flexDirection={"row"}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                          mr={1}
                        >
                          <Handshake sx={{ mr: 1 }}></Handshake>Compromiso:
                        </Typography>
                        <Typography color="info" variant="body2">
                          Tomamos conciencia para cumplir los acuerdos.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MKBox mb={{ xs: 5, md: 5 }} lineHeight={1} p={2} textAlign={"center"}>
                <MKTypography
                  color={"info"}
                  display="block"
                  variant="5"
                  fontWeight="bold"
                  mt={1}
                  mb={1.5}
                  sx={{
                    textAlign: "center",
                    position: "relative",
                    pb: 2,
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "2px",
                      background:
                        "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)",
                    },
                  }}
                >
                  {<Icon>checklist</Icon>} Objetivos de calidad
                </MKTypography>
                <Box sx={{ textAlign: "center", px: 2 }} display="block">
                  <Typography variant="body2" textAlign={"center"} component="div">
                    <Typography
                      component="div"
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: { xs: "start", md: "center", lg: "center" },
                        justifyContent: { xs: "start", md: "center", lg: "center" },
                      }}
                    >
                      <Check sx={{ mr: 2, color: "rgb(26, 115, 232)" }} />
                      Mantener un adecuado nivel de satisfacción del cliente aplicable en el medio.
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: { xs: "start", md: "center", lg: "center" },
                        justifyContent: { xs: "start", md: "center", lg: "center" },
                      }}
                    >
                      <Check sx={{ mr: 2, color: "rgb(26, 115, 232)" }} />
                      Certificarnos con la Norma ISO 9001:2015 hasta abril 2024.
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: { xs: "start", md: "center", lg: "center" },
                        justifyContent: { xs: "start", md: "center", lg: "center" },
                      }}
                    >
                      <Check sx={{ mr: 2, color: "rgb(26, 115, 232)" }} />
                      Fomentar una cultura organizacional que priorice la satisfacción del cliente.
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: { xs: "start", md: "center", lg: "center" },
                        justifyContent: { xs: "start", md: "center", lg: "center" },
                      }}
                    >
                      <Check sx={{ mr: 2, color: "rgb(26, 115, 232)" }} />
                      Alcanzar un máximo de 0,52% de tasa de deserción de clientes con base a la
                      cartera total en el 2026.
                    </Typography>
                  </Typography>
                </Box>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={12} width={"100%"}>
              <MKBox mb={{ xs: 5, md: 5 }} width={"100%"} lineHeight={1} p={2} textAlign={"center"}>
                <MKTypography
                  color={"info"}
                  display="block"
                  variant="5"
                  fontWeight="bold"
                  mt={1}
                  mb={1.5}
                  sx={{
                    textAlign: "center",
                    position: "relative",
                    pb: 2,
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "2px",
                      background:
                        "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)",
                    },
                  }}
                >
                  {<Icon>engineering</Icon>} Política de Seguridad y Salud Ocupacional
                </MKTypography>
                <Box sx={{ textAlign: "left", px: 2 }} display="block">
                  <Grid container justifyContent="flex-start" width={"100%"}>
                    <Grid item xs={12} md={12} sx={{ textAlign: { xs: "justify", md: "start" } }}>
                      <Typography variant="body2" mb={2}>
                        Para{" "}
                        <b style={{ color: "rgb(26, 115, 232)" }}>
                          AIRMAXTELECOM SOLUCIONES TECNOLÓGICAS S.A. {'"PLUS"'}
                        </b>{" "}
                        en sus servicios se considera a la Seguridad y Salud en el Trabajo, un
                        componente esencial en el desarrollo de los procesos que apalancan el giro
                        de negocio, razon por la cual nuestras operaciones están basadas en los
                        siguientes pilares:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ textAlign: { xs: "justify", md: "start" } }}>
                      <Box display={"flex"} flexDirection={"row"}>
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"flex-start"}
                          mr={1}
                        >
                          <LooksOne sx={{ mr: 1 }}> </LooksOne>
                        </Typography>
                        <Typography color="info" variant="body2">
                          Asignar los recursos financieros, tecnológicos y humanos para promover la
                          integración, implantación y mejora continua de nuestro Sistema de Gestión
                          de Seguridad y Salud en el Trabajo.
                        </Typography>
                      </Box>
                      <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"}>
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"flex-start"}
                          mr={1}
                        >
                          <LooksTwo sx={{ mr: 1 }}></LooksTwo>
                        </Typography>
                        <Typography color="info" variant="body2">
                          Generar conciencia preventiva y lograr el compromiso de nuestros
                          trabajadores y personal que intervenga en el giro de negocio.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ textAlign: { xs: "justify", md: "start" } }}>
                      <Box display={"flex"} flexDirection={"row"}>
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"flex-start"}
                          mr={1}
                        >
                          <Looks3 sx={{ mr: 1 }}></Looks3>
                        </Typography>
                        <Typography color="info" variant="body2">
                          Cumplir con la legislación vigente en Seguridad y Salud en el Trabajo.
                        </Typography>
                      </Box>
                      <Box display={"flex"} flexDirection={"row"}>
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(26, 115, 232)" }}
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"flex-start"}
                          mr={1}
                        >
                          <Looks4 sx={{ mr: 1 }}></Looks4>
                        </Typography>
                        <Typography color="info" variant="body2">
                          Evaluar, controlar los reisgos ocupacionales inherentes a nuestras
                          actividades.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
