import { Close, Mail, Person, Phone } from "@mui/icons-material";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Snackbar,
  Stack,
} from "@mui/material";
import { isAxiosError } from "axios";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import { sendEmail } from "controllers/SendEmailController";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

function FormContacts({ message = "", modal = false, action = () => {} }) {
  const [captcha, setCaptcha] = useState(false);
  const [msg, setMsg] = useState(message);
  const [emailFrom, setEmailFrom] = useState("");
  const [nameFrom, setNameFrom] = useState("");
  const [phoneFrom, setPhoneFrom] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(false);

  const [open, setOpen] = React.useState(false);

  const captchaRef = useRef();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = async (e) => {
    setSpinner(true);

    e.preventDefault();

    const email = {
      message: msg,
      subject: "Mensaje de contacto desde la pagina web PLUS",
      from_email: emailFrom,
      from_name: nameFrom,
      from_phone: phoneFrom,
      priority: "3",
    };

    const response = await sendEmail(email);
    console.log(response);
    if (isAxiosError(response)) {
      setError(true);
    }

    setOpen(true);
    captchaRef.current?.reset();
    setSpinner(false);

    setMsg("");
    setEmailFrom("");
    setNameFrom("");
    setPhoneFrom("");
  };

  const handleInputChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Filtra solo números
    setPhoneFrom(numericValue); // Actualiza el estado con el valor filtrado
  };

  return (
    <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <MKBox
        variant="gradient"
        bgColor="info"
        coloredShadow="info"
        borderRadius="lg"
        p={2}
        mx={2}
        mt={-3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MKTypography variant="h3" color="white">
          Contáctanos
        </MKTypography>
        {modal && <Close color="white" onClick={action} sx={{ cursor: "pointer" }} />}
      </MKBox>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        sx={{ position: "sticky", left: 0, right: 0 }}
      >
        {error ? (
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Ha ocurrido un error, por favor intente de nuevo.
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            Mensaje enviado con éxito
          </Alert>
        )}
      </Snackbar>

      <MKBox p={3}>
        <MKBox
          width="100%"
          component="form"
          onSubmit={handleSubmit}
          method="post"
          autoComplete="off"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel sx={{ fontSize: "small" }} color="info">
                  Nombre Completo
                </FormLabel>
                <Input
                  value={nameFrom}
                  placeholder="Nombre Completo"
                  startAdornment={<Person sx={{ mx: "5px" }} />}
                  required
                  onChange={(e) => setNameFrom(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel sx={{ fontSize: "small" }} color="info">
                  Teléfono
                </FormLabel>
                <Input
                  type="tel"
                  placeholder="Teléfono"
                  startAdornment={<Phone sx={{ mx: "5px" }} />}
                  required
                  onChange={handleInputChange}
                  inputProps={{ pattern: "[0-9]*" }}
                  value={phoneFrom}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel sx={{ fontSize: "small" }} color="info">
                  Correo Electrónico
                </FormLabel>
                <Input
                  value={emailFrom}
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmailFrom(e.target.value)}
                  startAdornment={<Mail sx={{ mx: "5px" }} />}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <MKInput
                value={msg}
                variant="standard"
                label="Su mensaje"
                placeholder=""
                InputLabelProps={{ shrink: true }}
                multiline
                fullWidth
                onChange={(e) => setMsg(e.target.value)}
                rows={4}
                required
              />
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center", py: 2 }}>
            <ReCAPTCHA
              ref={captchaRef}
              // eslint-disable-next-line no-undef
              sitekey={process.env.REACT_APP_SITE_KEY_CAPTCHA}
              onChange={() => setCaptcha(!captcha)}
            />
          </Box>
          <Grid container item justifyContent="center" xs={12} mt={3} mb={2}>
            <MKButton disabled={!captcha} type="submit" variant="gradient" color="info">
              {!spinner ? (
                "Enviar"
              ) : (
                <Stack>
                  <CircularProgress color="white" size="20px" />
                </Stack>
              )}
            </MKButton>
          </Grid>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

FormContacts.propTypes = {
  message: PropTypes.string,
  modal: PropTypes.bool,
  action: PropTypes.func,
};

export default FormContacts;
