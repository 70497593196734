// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/plus/home_bg.png";
import MKTypography from "components/MKTypography";

import Card from "@mui/material/Card";
import { Box, Container } from "@mui/material";
import CapacityPerYear from "./Components/CapacityPerYear";

function Capacity() {
  return (
    <>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "overlay",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Nuestra Capacidad
          </MKTypography>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -6,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox component="section" py={2}>
            <Container>
              <MKTypography color={"dark"}>
                Monitoreamos y optimizamos constantemente la utilización diaria de nuestra capacidad
                de salida internacional, asegurando un servicio de acceso a Internet confiable y de
                alta calidad. Esto garantiza que nuestros clientes disfruten de una conexión
                estable, rápida y eficiente en todo momento, incluso en períodos de alta demanda.
              </MKTypography>
              <Box>
                <CapacityPerYear />
              </Box>
            </Container>
          </MKBox>
        </Card>
      </MKBox>
    </>
  );
}

export default Capacity;
