import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { getAllGlossaryTerms } from "controllers/GlossaryController";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(52, 71, 103)",
    color: theme.palette.common.white,
    fontSize: 20,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableGlossaryTerms() {
  const [glossary, setGlossary] = useState([]);

  useEffect(() => {
    const fetchGlossary = async () => {
      try {
        const data = await getAllGlossaryTerms();
        setGlossary(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchGlossary();
  }, []);
  return (
    <>
      <Grid container item sx={{ textAlign: "center", width: "100%" }}>
        <MKTypography variant="h3" mb={6} sx={{ textAlign: "center", width: "100%" }}>
          Definiciones de los principales términos usados
        </MKTypography>
      </Grid>
      <TableContainer component={Paper} sx={{ px: { xs: 0, md: 5, lg: 10 } }}>
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead sx={{ display: "contents" }}>
            <TableRow>
              <StyledTableCell>Término</StyledTableCell>
              <StyledTableCell>Definición</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {glossary.length > 0 ? (
              <>
                {glossary.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.term}
                    </StyledTableCell>
                    <StyledTableCell>{row.definition}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
