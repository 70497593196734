// layouts/MainLayout.js
import React, { useEffect, useState } from "react";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import MKBox from "components/MKBox";

// Routes
import footerRoutes from "footer.routes";
import { routers_header } from "routes";
import { getAllSocialNetworks } from "controllers/SocialNetworks";
import { getAllContacts } from "controllers/ContactController";
import FloatingButtons from "./Components/Floating";
// MainLayout ahora no necesita recibir props, se maneja automáticamente
// eslint-disable-next-line react/prop-types
const MainLayout = ({ children }) => {
  const [social, setSocial] = useState([]);
  const [contacts, setContacts] = useState([]);

  const getSocialNetworks = async () => {
    try {
      const data = await getAllSocialNetworks();
      setSocial(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getContacts = async () => {
    try {
      const data = await getAllContacts();
      setContacts(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getContacts();
    getSocialNetworks();
  }, []);
  return (
    <div>
      {/* El Navbar (Header) */}
      <DefaultNavbar
        routes={routers_header}
        sticky
        email={
          contacts.length > 0 &&
          contacts.filter((contact) => contact.name.includes("Soporte"))[0].email1
        }
        phone={
          contacts.length > 0 &&
          contacts.filter((contact) => contact.name.includes("Ventas"))[0].phone1
        }
      />

      {/* Contenido principal de la página */}
      <>{children}</>

      {/* El Footer */}
      <MKBox pt={7} mt={6}>
        <DefaultFooter content={footerRoutes} social={social} contacts={contacts} />
      </MKBox>

      <FloatingButtons social={social} />
    </div>
  );
};

export default MainLayout;
