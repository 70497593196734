// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/plus/home_bg.png";
import MKTypography from "components/MKTypography";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { getInfoPayment } from "controllers/PaymentController";
import PaymentInfo from "./components/PaymentInfo";
import { Logout } from "@mui/icons-material";

//Imagen de los pagos
import image from "assets/plus/payment_disabled.jpeg";

function Pagos() {
  const [user, setUser] = useState("");
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [infoPayment, setInfoPayment] = useState(null);
  //const [token, setToken] = useState();
  const disabled = true;

  const handleSubmit = async () => {
    setSpinner(true);
    const data = { user, password };
    var info = await getInfoPayment(data);
    if (info) {
      setInfoPayment(info);
      setError(false);
      setSpinner(false);
    } else {
      setUser("");
      setPassword("");
      setSpinner(false);
      setError(true);
    }
  };

  return (
    <>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "overlay",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Pagos
          </MKTypography>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -6,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {disabled ? (
            <Box>
              <MKBox
                component="img"
                src={image}
                alt={"Pagos deshabilitados"}
                borderRadius="lg"
                shadow="md"
                width="100%"
                position="relative"
                zIndex={1}
                maxWidth={500}
              />
            </Box>
          ) : !infoPayment ? (
            <Box
              m={4}
              p={5}
              display={"flex"}
              flexDirection={"column"}
              maxWidth={700}
              width={"80%"}
              minWidth={400}
              sx={{
                borderRadius: "50px",
                boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
              }}
            >
              <Typography variant="subtitle1" align="center" py={2}>
                Ingresar al portal de pagos
              </Typography>
              {error && (
                <Alert variant="standard" severity="error" sx={{ fontSize: "small", my: 2 }}>
                  Usuario no encontrado
                </Alert>
              )}

              <TextField
                value={user}
                onChange={(e) => setUser(e.target.value)}
                id="standard-basic"
                name="user"
                label="Usuario"
                variant="standard"
                sx={{ my: 2 }}
                required
              />
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="outlined-password-input"
                label="Contraseña"
                type="password"
                autoComplete="current-password"
                variant="standard"
                name="password"
                sx={{ my: 2 }}
                required
              />
              <Button
                disabled={!(user && password) || spinner}
                variant="contained"
                onClick={() => handleSubmit()}
                sx={{ color: "white !important", my: "20px" }}
              >
                {!spinner ? (
                  "Ingresar"
                ) : (
                  <Stack>
                    <CircularProgress color="white" size="20px" />
                  </Stack>
                )}
              </Button>
            </Box>
          ) : (
            <Box maxWidth={"100%"}>
              <Box width={"100%"} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{ color: "white !important" }}
                  onClick={() => {
                    setInfoPayment(null);
                    setUser(null);
                    setPassword(null);
                  }}
                >
                  <Logout sx={{ mr: 1 }} />
                  {"Cerrar Sesión"}
                </Button>
              </Box>
              <PaymentInfo data={infoPayment} />
            </Box>
          )}
        </Card>
      </MKBox>
    </>
  );
}

export default Pagos;
