import { Box, Card } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
// Image
import bgImage from "assets/plus/home_bg.png";
//import { TitleUnderlineComponent } from "./components/Title";
//Imagen de los pagos
//import image from "assets/plus/payment_disabled.jpeg";
export const QualityParameters = () => {
  return (
    <>
      <MKBox
        minHeight="25rem"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "overlay",
          display: "grid",
          placeItems: "center",
        }}
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          Parámetros de Calidad
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          textAlign: { xs: "justify", md: "start" },
        }}
      >
        <Box p={6} display={"flex"} justifyContent={"center"}>
          {/*<MKBox
            component="img"
            src={image}
            alt={"Pagos deshabilitados"}
            borderRadius="lg"
            shadow="md"
            width="100%"
            position="relative"
            zIndex={1}
            maxWidth={500}
          />
          */}
        </Box>
      </Card>
    </>
  );
};
