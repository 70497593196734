// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Presentation page sections
import Counters from "pages/Home/sections/Counters";

// Images
//import bgImage from "assets/images/bg-presentation.jpg";
import bg_Image from "assets/plus/home_bg.png";
import PlanHome from "./sections/PlanHome";
import RoatingCardHomePlus from "./components/RoatingCardHomePlus";
import { useEffect, useState } from "react";
import CarouselComponent from "components/Carousel/Carousel";
import { getAllImages } from "controllers/ImagesController";

import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/material";
import FadeInComponent from "components/FadeInCompontent";
import { Customers } from "./sections/Customers";
import { SucursalesMap } from "./sections/SucusalesMap";
import ModalPromotional from "components/Modals/ModalPromotional";

function Home() {
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const fetchImages = async () => {
    try {
      const data = await getAllImages();
      if (data.filter((img) => img.type == "promotion").length > 0) setOpen(true);
      setImages(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchImages();
  }, []);
  return (
    <>
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(135deg, rgba(2, 3, 129,0) 0%, rgba(40, 116, 252,0) 100%), url(${bg_Image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          px: { xs: "0%", lg: "10%" },
        }}
      >
        {images.length > 0 ? (
          <>
            {images.filter((img) => img.type == "promotion").length > 0 ? (
              <ModalPromotional
                open={open}
                handleClose={handleClose}
                image={images.filter((img) => img.type == "promotion")[0]}
              />
            ) : null}

            <CarouselComponent
              images={images.filter((img) => img.type == "header")}
              style={{ display: "flex" }}
              height="100%"
            />
          </>
        ) : (
          <Stack spacing={2} direction="row">
            <CircularProgress sx={{ color: "white !important" }} />
          </Stack>
        )}
      </MKBox>
      <Card
        sx={{
          p: 3,
          mx: { xs: 2, lg: 3 },
          mt: -6,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          zIndex: 4,
        }}
      >
        <Counters />
        <PlanHome />
        {images.length > 0 ? (
          <>
            <FadeInComponent>
              <SucursalesMap map={images.filter((img) => img.type.includes("map"))[0].file_data} />
            </FadeInComponent>
            <FadeInComponent>
              <Customers />
            </FadeInComponent>
            <FadeInComponent threshold={0.1}>
              <RoatingCardHomePlus images={images.filter((img) => img.type.includes("card"))} />
            </FadeInComponent>
          </>
        ) : (
          <Stack spacing={2} direction="row">
            <CircularProgress sx={{ color: "white !important" }} />
          </Stack>
        )}

        {images.length > 0 ? (
          <>
            <FadeInComponent threshold={0.2}>
              <CarouselComponent
                images={images.filter((img) => img.type == "footer" || img.type == "promotion")}
              />
            </FadeInComponent>
          </>
        ) : null}
      </Card>
    </>
  );
}

export default Home;
