import api from "api";

export const sendEmailService = async (data) => {
  try {
    const response = await api.post("/send-email", data);
    return response;
  } catch (error) {
    return error;
  }
};
