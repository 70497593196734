// Pages

import Pagos from "pages/LandingPages/Pagos";
import GlossaryTerms from "pages/LandingPages/GlossaryTerms";
import Tarifas from "pages/LandingPages/Tarifas";
import PlanesComponent from "pages/LandingPages/Tarifas/[id]";
import PageContentComponent from "pages/LandingPages/PageContent/[id]";
import { PrivacyPolicies } from "pages/LandingPages/OtherPages/PrivacyPolicies";
import { InternetSecurityGuide } from "pages/LandingPages/OtherPages/InternetSecurityGuide";
import AboutUs from "pages/LandingPages/AboutUs";
import ContactUs from "pages/LandingPages/ContactUs";
import Home from "pages/Home";
import { QualityParameters } from "pages/LandingPages/OtherPages/QualityParameters";
import { LocalAtm } from "@mui/icons-material";
import Capacity from "pages/LandingPages/Capacity";
const routes = [
  {
    name: "Inicio",
    route: "/",
    component: <Home />,
  },
  {
    name: "Nosotros",
    route: "/pages/about-us",
    component: <AboutUs />,
  },
  {
    name: "Planes",
    route: "/pages/planes/:id",
    component: <PlanesComponent />,
    disabled: 1,
  },
  {
    name: "Políticas de Privacidad",
    route: "/pages/privacity-policies",
    component: <PrivacyPolicies />,
    disabled: 1,
  },
  {
    name: "Guía de Seguridad y Control de Internet",
    route: "/pages/internet-security-guide",
    component: <InternetSecurityGuide />,
    disabled: 1,
  },
  {
    route: "/pages/quality-parameters",
    name: "Parámetros de Calidad",
    component: <QualityParameters />,
    footer: 1,
  },
  {
    route: "/pages/capacidad",
    name: "Nuestra Capacidad",
    component: <Capacity />,
    footer: 1,
  },
  {
    name: "Contenido",
    route: "/pages/:id",
    component: <PageContentComponent />,
    disabled: 1,
  },
  {
    name: "Tarifa",
    route: "/pages/planes",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Planes",
        collapse: [
          {
            name: "Internet por Fibra",
            route: "/pages/planes/fibra",
            dinamyc: 1,
          },
          {
            name: "Internet por Wireless",
            route: "/pages/planes/wireless",
            dinamyc: 1,
          },
          {
            name: "Planes Corporativos",
            route: "/pages/planes/corporate",
            dinamyc: 1,
          },
        ],
      },
    ],
  },
  {
    name: "Tarifas",
    route: "/pages/planes",
    component: <Tarifas />,
    footer: 1,
  },
  {
    name: "Pagos",
    route: "/pages/pagos",
    component: <Pagos />,
    icon: <LocalAtm sx={{ color: "white !important" }} />,
  },
  {
    name: "Glosario de Términos",
    route: "/pages/glossary-terms",
    component: <GlossaryTerms />,
    footer: 1,
  },
  {
    name: "Contáctanos",
    route: "/pages/contact-us",
    component: <ContactUs />,
  },
];

const routers_header = routes.filter((route) => !route.footer && !route.disabled);

export { routes, routers_header };
