import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import FadeInComponent from "components/FadeInCompontent";
import { Icon } from "@mui/material";
import "./styles.css";
function PlanMediaCard({ title, description, price, image }) {
  const phone = "593992462282";
  const message = `Hola, estoy interesado en obtener más información sobre el plan de internet '${title}'. ¿Podrían brindarme detalles sobre sus características y condiciones? Muchas gracias`;
  const encodedMessage = encodeURIComponent(message); // Codifica el mensaje
  const url = `https://api.whatsapp.com/send/?phone=${phone}&text=${encodedMessage}`;
  return (
    <FadeInComponent style={{ display: "flex", justifyContent: "center" }}>
      <Card
        sx={{
          maxWidth: "95%",
          boxShadow:
            "10px 9px 17px 8px rgba(195,219,255,0.5), -10px -9px 17px 8px rgba(195,219,255,0.1)",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            maxWidth: "98%",
          },
        }}
        color="info"
      >
        <CardMedia
          component="img"
          alt={title}
          sx={{
            margin: 0,
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: "#13145f",
          }}
          image={image}
        />
        <CardContent
          sx={{
            backgroundColor: "#130f66",
            color: "white",
          }}
        >
          <Button
            className="glow-on-hover"
            href={url}
            target="_blank"
            //onClick={() => action(`Hola`)}
            sx={{
              color: "white !important",
              //color: "",
              backgroundImage:
                "linear-gradient(326deg, rgba(80,14,93,1) 17%, rgba(20,20,129,1) 59%, rgba(75,189,255,1) 96%)",
              //  "linear-gradient(326deg, rgba(80,14,93,1) 17%, rgba(20,20,129,1) 59%, rgba(75,189,255,1) 96%)",
              width: "100%",
              padding: "10px",
              borderRadius: "10px",
              textAlign: "center",
              my: 3,
              boxShadow:
                "10px 9px 18px 9px rgba(172, 170, 255, 0.1),-10px -9px 18px 9px rgba(153, 178, 255, 0.1)",
              //cursor: "pointer",
            }}
          >
            {title}
          </Button>
          <Typography variant="body2" sx={{ color: "white !important", cursor: "default" }}>
            {description}
          </Typography>
          <Typography
            variant="subtitle2"
            align="right"
            fontSize={15}
            sx={{ color: "rgba(255,255,255,0.7) !important", cursor: "default" }}
          >
            $ {price}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            backgroundColor: "#130f66",
            borderRadius: "0px 0px 10px 10px",
            padding: 0,
          }}
        >
          <Button
            href={url}
            target="_blank"
            variant="outlined"
            sx={{
              color: "white !important",
              backgroundColor: "rgba(0, 47, 255, 0.17) !important",
              fontWeight: "bolder !important",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              py: 2,
              "&:hover": {
                opacity: 1,
                backgroundColor: "rgba(6, 79, 163, 0.6) !important",
              },
              "& .material-icons, .material-icons-round,": {
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },

              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
            size="large"
            fullWidth
          >
            <b>Contratar Ahora</b>
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </Button>
        </CardActions>
      </Card>
    </FadeInComponent>
  );
}
PlanMediaCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  speed: PropTypes.string,
  image: PropTypes.string,
  action: PropTypes.func,
};

export default PlanMediaCard;
