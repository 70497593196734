import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getCapacityPerYear } from "controllers/ImagesController";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CapacityPerYear() {
  const [value, setValue] = useState(0);
  const [capacityPerYearInfo, setCapacity] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchCapacity = async () => {
    try {
      const data = await getCapacityPerYear();

      setCapacity(data);
      setValue(data.length - 1);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCapacity();
  }, []);

  return (
    <Box sx={{ width: "100%", my: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          {capacityPerYearInfo.length > 0 &&
            capacityPerYearInfo.map((info, index) => (
              <Tab
                label={info.year}
                {...a11yProps(index)}
                key={index}
                sx={{ fontSize: "20px", fontWeight: "medium" }}
              />
            ))}
        </Tabs>
      </Box>
      {capacityPerYearInfo.length > 0 &&
        capacityPerYearInfo.map((info, index) => (
          <CustomTabPanel value={value} index={index} key={index}>
            <Grid container spacing={2}>
              {info.months.map((month, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt={`Capacidad del mes de ${month.name_month} del ${info.year}`}
                      image={`data:image/webp;base64,${month.image}`}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" align="center">
                        {`${month.name_month} del ${info.year}`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CustomTabPanel>
        ))}
    </Box>
  );
}
