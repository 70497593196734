import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

// Material Kit 2 React routes
import { routes } from "routes";
import MainLayout from "layouts/MainLayout";
import Home from "pages/Home";

const RouteWithTitle = ({ element, title }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title == "Inicio" ? "PLUS - Internet de alta velocidad" : title + " - PLUS";
  }, [location, title]);

  return element;
};

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes
      .filter((r) => !r.dinamyc)
      .map((route, index) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              exact
              path={route.route}
              element={<RouteWithTitle element={route.component} title={route.name} />}
              key={index}
            />
          );
        }

        return null;
      });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainLayout>
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Home />} key={1000} />
          <Route path="*" element={<Navigate to="/" />} key={1001} />
        </Routes>
      </MainLayout>
    </ThemeProvider>
  );
}
