import { searchFileByName } from "services/FilesService";
import { fetchFiles } from "services/FilesService";

export const getAllFiles = async () => {
  try {
    const files = await fetchFiles();

    return files.data.filter((term) => term.is_active);
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
export const getFileByName = async (name) => {
  try {
    const data = {
      name: name,
    };
    const files = await searchFileByName(data);

    return files.data.filter((term) => term.is_active);
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
