// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "components/Cards/CounterCards/DefaultCounterCard";

import { useEffect, useState } from "react";
import { getInfoSectionsByType } from "controllers/PageSectionsController";

function Counters() {
  const [info, setInfo] = useState([]);

  const fetchImages = async () => {
    try {
      const data = await getInfoSectionsByType("service");
      setInfo(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);
  return (
    <MKBox component="section" py={3} sx={{ display: { xs: "none", md: "block" } }}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          {info &&
            info.map((inf, inx) => (
              <Grid item xs={12} md={4} key={inx} display={"flex"}>
                {inx > 0 && (
                  <Divider
                    orientation="vertical"
                    sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
                  />
                )}
                <DefaultCounterCard
                  //count={200}
                  //suffix="+"
                  image={inf.image}
                  title={inf.title}
                  description={inf.description}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
