import { sendEmailService } from "services/SendEmail";

export const sendEmail = async (data) => {
  try {
    const response = await sendEmailService(data);

    return response;
  } catch (error) {
    console.error("Error sending email:", error);
    throw error;
  }
};
