import { fetchBranch } from "services/SucursalService";

export const getAllBranchs = async () => {
  try {
    const branchs = await fetchBranch();

    return branchs.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
