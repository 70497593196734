import SpeedDial from "@mui/material/SpeedDial";
import { ArrowUpward, FacebookOutlined, Instagram, WhatsApp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Box, Fab } from "@mui/material";
import PropTypes from "prop-types";

import tiktok_icon from "assets/plus/tiktok_icon.svg";
const FloatingButtons = ({ social }) => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Cambia 100 por el número de píxeles que prefieras
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Limpieza del evento al desmontar el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Hace que el scroll sea animado
    });
  };
  return (
    <>
      {showButton ? (
        <>
          <SpeedDial
            title="Subir"
            ariaLabel="SpeedDial arrow up"
            sx={{
              position: "fixed",
              bottom: 10,
              right: 5,
              alignItems: "flex-end",
              px: 2,
            }}
            onClick={scrollToTop}
            icon={<ArrowUpward sx={{ color: "black" }} />}
          ></SpeedDial>
        </>
      ) : null}
      <Box
        sx={{
          position: "fixed",
          bottom: `40%`,
          top: `40%`,
          right: 0,
          alignItems: "flex-end",
          px: 1,
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {social.map((s, index) => (
          <Box key={index}>
            <Fab
              title={s.name}
              href={s.url}
              target="_blank"
              size="medium"
              variant="circular"
              sx={{
                my: 0.3,
                borderRadius: "500px",
                background:
                  s.name == "Facebook"
                    ? "rgb(59, 89, 152)"
                    : s.name == "Instagram"
                    ? " linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)"
                    : s.name == "Whatsapp"
                    ? "rgb(37, 211, 102)"
                    : "black",
                "&:hover": {
                  backgroundColor:
                    s.name == "Facebook"
                      ? "rgb(59, 89, 152)"
                      : s.name == "Instagram"
                      ? " linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)"
                      : s.name == "Whatsapp"
                      ? "rgb(37, 211, 102)"
                      : "black !important",
                },
              }}
            >
              {s.name == "Facebook" ? (
                <FacebookOutlined
                  fontSize="medium"
                  sx={{
                    color: "white !important",
                    textAlign: "center !important",
                    display: "flex !important",
                    justifyContent: "center !important",
                  }}
                />
              ) : s.name == "Instagram" ? (
                <Instagram fontSize="medium" sx={{ color: "white !important" }} />
              ) : s.name == "Whatsapp" ? (
                <WhatsApp fontSize="medium" sx={{ color: "white !important" }} />
              ) : s.name == "Tiktok" ? (
                <img
                  src={tiktok_icon}
                  alt="Icon"
                  width={"100%"}
                  style={{ padding: "4px" }}
                  loading="lazy"
                />
              ) : null}
            </Fab>
          </Box>
        ))}
      </Box>
    </>
  );
};

FloatingButtons.propTypes = {
  social: PropTypes.array,
};

export default FloatingButtons;
