// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import SucursalCard from "./SucursalCard";
import { getAllBranchs } from "controllers/SucursalController";
import { useEffect, useState } from "react";

function Sucursales() {
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    const fetchBranch = async () => {
      try {
        const data = await getAllBranchs();
        setSucursales(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBranch();
  }, []);
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" my={6}>
            Nuestras Sucursales
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {sucursales.map((sucursal, index) => (
            <Grid item xs={12} sm={6} lg={3} key={index}>
              <SucursalCard
                title={sucursal.name}
                description={sucursal.address}
                image={sucursal.imagen ? `${sucursal.imagen}` : null}
                imageProm={sucursal.image_promotional ? `${sucursal.image_promotional}` : null}
                schedule={sucursal.schedule}
                action={{
                  type: "internal",
                  color: "info",
                  label: "Contactar Ahora",
                }}
                phone1={sucursal.phone1}
                phone2={sucursal.phone2}
                lat={sucursal.lat}
                long={sucursal.long}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Sucursales;
