import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

const FadeInComponent = ({ children, threshold = 0.2, style = {} }) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: threshold }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={cardRef}
      style={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(20px)" : "translateY(0)",
        transition: "opacity 1s ease, transform 1s ease",
        width: "100%",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

FadeInComponent.propTypes = {
  children: PropTypes.node.isRequired,
  threshold: PropTypes.number,
  style: PropTypes.object,
};

export default FadeInComponent;
