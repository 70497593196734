import { fetchContacts } from "services/ContactService";

export const getAllContacts = async () => {
  try {
    const contacts = await fetchContacts();

    return contacts.data.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
