import { fetchSocialNetworks } from "services/SocialNetworkService";

export const getAllSocialNetworks = async () => {
  try {
    const social = await fetchSocialNetworks();

    return social.data;
  } catch (error) {
    console.error("Error fetching social networks:", error);
    throw error;
  }
};
