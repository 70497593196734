// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React examples
import RotatingCard from "components/Cards/RotatingCard";
import RotatingCardFront from "components/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "components/Cards/RotatingCard/RotatingCardBack";

import TopInfoCard from "components/Cards/InfoCards/TopInfoCard";
import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getInfoSectionsByType } from "controllers/PageSectionsController";

function RoatingCardHomePlus({ images }) {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const data = await getInfoSectionsByType("propertie");
        setInfo(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchImages();
  }, []);
  return (
    <Container sx={{ my: 6 }}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        sx={{
          mx: "auto",
          boxShadow: "18px 16px 40px 9px rgba(0,0,0,0.1), -18px -16px 40px 9px rgba(0,0,0,0.1)",
          borderRadius: "10px",
          backgroundColor: "rgba(157,185,227, 0.2)",
          width: "100%",
        }}
        p={6}
      >
        <Grid item xs={12} md={4} lg={4} sx={{ height: "100%", width: "100%", pl: "0 !important" }}>
          <RotatingCard>
            <RotatingCardFront
              image={images.filter((img) => img.type.includes("front"))[0].file_data}
            />
            <RotatingCardBack
              image={images.filter((img) => img.type.includes("back"))[0].file_data}
            />
          </RotatingCard>
        </Grid>
        <Grid item xs={12} md={8} lg={8} sx={{ pl: "0 !important" }}>
          <Grid container spacing={1}>
            {info &&
              info.map((inf, inx) => (
                <Grid item xs={12} md={6} key={inx}>
                  <TopInfoCard icon={inf.icon} title={inf.title} description={inf.description} />
                  {inf.title == "SpeedTest" && (
                    <Box width={"100%"}>
                      <Button
                        href="https://airmaxtelecom.speedtestcustom.com/"
                        target="_blank"
                        sx={{
                          backgroundColor: "rgba(109, 173, 254, 0.5)",
                          borderRadius: "0px 0px 10px 10px",
                        }}
                        fullWidth
                      >
                        SPEEDTEST
                      </Button>
                    </Box>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

RoatingCardHomePlus.propTypes = {
  images: PropTypes.array,
};

export default RoatingCardHomePlus;
