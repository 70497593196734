// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/plus/home_bg.png";
import MKTypography from "components/MKTypography";

import Card from "@mui/material/Card";
import TableGlossaryTerms from "./components/TableGlossaryTerms";

function GlossaryTerms() {
  return (
    <>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "overlay",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Glosario de Términos
          </MKTypography>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -6,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <TableGlossaryTerms />
        </Card>
      </MKBox>
    </>
  );
}

export default GlossaryTerms;
