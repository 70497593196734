import { fetchGlossary } from "services/GlossaryService";

export const getAllGlossaryTerms = async () => {
  try {
    const glossary = await fetchGlossary();

    return glossary.data.filter((term) => term.is_active);
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
