import { fetchPlanTypes } from "services/PlanService";
import { searchPlanByType } from "services/PlanService";
import { fetchPlans } from "services/PlanService";

export const getAllPlans = async () => {
  try {
    const plans = await fetchPlans();

    return plans.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
export const getPlansByType = async (type) => {
  try {
    const data = { type: type };
    const plans = await searchPlanByType(data);

    return plans.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
export const getPlansFibra = async () => {
  try {
    const data = {
      type: "fibra",
    };
    const plans = await searchPlanByType(data);

    return plans.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const getPlansWireless = async () => {
  try {
    const data = {
      type: "wireless",
    };
    const plans = await searchPlanByType(data);

    return plans.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const getPlanTypes = async () => {
  try {
    const types = await fetchPlanTypes();

    return types.data.data;
  } catch (error) {
    console.error("Error fetching plan types:", error);
    throw error;
  }
};
