// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Typography } from "@mui/material";

function TopInfoCard({ color, icon, title, description, direction, small }) {
  return (
    <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}>
      <Typography
        sx={{
          textAlign: "center",
          p: 0,
          fontSize: "50px",
          color: "#1a73e8",
          lineHeight: "0",
          mb: 2,
        }}
      >
        {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
      </Typography>
      <MKTypography
        color={color}
        display="block"
        variant="5"
        fontWeight="bold"
        mb={1.5}
        sx={{
          textAlign: "center",
          position: "relative",
          lineHeight: "1",
          pb: 2,
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "2px",
            background:
              "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)",
          },
        }}
      >
        {title}
      </MKTypography>
      <MKTypography
        sx={{ textAlign: "center", px: 2 }}
        display="block"
        variant={small ? "button" : "body2"}
        color="text"
      >
        {description}
      </MKTypography>
    </MKBox>
  );
}

// Setting default props for the TopInfoCard
TopInfoCard.defaultProps = {
  color: "info",
  direction: "left",
  small: false,
};

// Typechecking props for the TopInfoCard
TopInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(["left", "right", "center"]),
  small: PropTypes.bool,
};

export default TopInfoCard;
