import { fetchInfoSectionsByType } from "services/PageSectionsService";
import { fetchInfoSections } from "services/PageSectionsService";

export const getAllInfoSections = async () => {
  try {
    const info = await fetchInfoSections();

    return info.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};
export const getInfoSectionsByType = async (type) => {
  try {
    const info = await fetchInfoSectionsByType(type);

    return info.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};
