// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useEffect, useRef, useState } from "react";
import RotatingCard from "components/Cards/RotatingCard";
import RotatingCardFront from "components/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "components/Cards/RotatingCard/RotatingCardBack";

function SucursalCard({
  image,
  imageProm = null,
  title,
  description,
  schedule,
  action,
  phone1,
  phone2,
  lat,
  long,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.4 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const cardActionStyles = {
    display: "flex",
    alignItems: "center",
    width: "max-content",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };

  const imageTemplate = (
    <MKBox position="relative" borderRadius="lg">
      <MKBox
        component="img"
        src={`data:image/webp;base64,${image}`}
        alt={title}
        borderRadius="lg"
        shadow="md"
        width="100%"
        position="relative"
        zIndex={1}
      />
      <MKBox
        borderRadius="lg"
        shadow="md"
        width="100%"
        height="100%"
        position="absolute"
        left={0}
        top={0}
        sx={{
          backgroundImage: `url(data:image/webp;base64,${image})`,
          transform: "scale(0.94)",
          filter: "blur(12px)",
          backgroundSize: "cover",
        }}
      />
    </MKBox>
  );
  return (
    <Card
      ref={cardRef}
      sx={{
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(20px)" : "translateY(0)",
        transition: "opacity 1s ease, transform 1s ease",
      }}
    >
      {imageProm ? (
        <RotatingCard height="100%">
          <RotatingCardFront image={image} />
          <RotatingCardBack image={imageProm ? imageProm : image} />
        </RotatingCard>
      ) : action.type === "internal" ? (
        <Link to={action.route}>{imageTemplate}</Link>
      ) : (
        <MuiLink href={action.route} target="_blank" rel="noreferrer">
          {imageTemplate}
        </MuiLink>
      )}

      <MKBox pt={2} pb={3}>
        {action.type === "internal" ? (
          <Link to={`tel:${phone1.replace(/\s+/g, "")}`} sx={cardActionStyles}>
            <MKTypography variant="h5" gutterBottom>
              {title}
            </MKTypography>
          </Link>
        ) : (
          <MuiLink
            href={`tel:${phone1.replace(/\s+/g, "")}`}
            target="_blank"
            rel="noreferrer"
            sx={cardActionStyles}
          >
            <MKTypography variant="h5" gutterBottom>
              {title}
            </MKTypography>
          </MuiLink>
        )}

        <MKTypography variant="body2" component="p" color="text">
          <b>Dirección: </b> {description}
        </MKTypography>
        <MKTypography variant="body2" component="p" color="text">
          <b>Horario: </b> {schedule}
        </MKTypography>
        <MKTypography variant="body2" component="p" color="text">
          <b>Teléfono: </b> {phone1}
        </MKTypography>
        {phone2 ? (
          <>
            <MKTypography variant="body2" component="p" color="text">
              <b>Teléfono 2: </b> {phone2}
            </MKTypography>
          </>
        ) : null}

        {action.type === "internal" ? (
          <MKTypography
            component={Link}
            to={`tel:${phone1.replace(/\s+/g, "")}`}
            variant="body2"
            fontWeight="regular"
            color={action.color}
            textTransform="capitalize"
            sx={cardActionStyles}
          >
            {action.label}
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        ) : (
          <MKTypography
            component={MuiLink}
            href={`tel:${phone1.replace(/\s+/g, "")}`}
            target="_blank"
            rel="noreferrer"
            variant="body2"
            fontWeight="regular"
            color={action.color}
            textTransform="capitalize"
            sx={cardActionStyles}
          >
            {action.label}
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        )}
        {lat && long ? (
          <MKTypography
            component={Link}
            target="_blank"
            to={`https://www.google.com/maps?q=${lat},${long}`}
            variant="body2"
            fontWeight="regular"
            color={action.color}
            textTransform="capitalize"
            sx={cardActionStyles}
          >
            Ir a la Ubicación
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        ) : null}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the SucursalCard
SucursalCard.propTypes = {
  image: PropTypes.string.isRequired,
  imageProm: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  schedule: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "inherit",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "text",
    ]).isRequired,
  }).isRequired,
  phone1: PropTypes.string.isRequired,
  phone2: PropTypes.string,
  lat: PropTypes.string,
  long: PropTypes.string,
};

export default SucursalCard;
