import axios from "axios";

const apiPayment = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: "http://45.70.13.23:8011/", // Base de la API
  timeout: 10000, // Tiempo máximo de espera para una solicitud (opcional)
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const loginPayment = (data) => apiPayment.post("pagos/login/?=", data);
export const statementAccount = (data) => apiPayment.post("pagos/estadocuenta/", data);
