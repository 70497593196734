// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import { useEffect, useState } from "react";
import { getPlansFibra } from "controllers/PlansController";
import PlanMediaCard from "components/Cards/MediaCard";
import PlanTypes from "./PlanTypes";

function PlanHome() {
  const [plans, setPlans] = useState([]);

  const getPlans = async () => {
    try {
      const data = await getPlansFibra();
      setPlans(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={12}>
          <MKTypography
            sx={{
              width: "100%",
              textAlign: "center",
              pb: 2,
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "2px",
                background:
                  "linear-gradient(326deg, rgba(80,14,93,0.1) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0.1) 96%)",
              },
            }}
            color="info"
            variant="h2"
            mb={3}
          >
            Elige tu Plan Ideal
          </MKTypography>

          <MKTypography variant="h5" mb={6} sx={{ width: "100%", textAlign: "center" }}>
            Te ofrecemos la mejor experiencia en línea, con tecnología de Fibra Óptica y Wireless.
            Cada uno de nuestros planes están diseñados para adaptarse a tus necesidades, así como a
            las de tu familia o empresa.
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {plans.slice(0, 3).map((plan, index) => (
            <Grid
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              item
              xs={12}
              md={4}
              lg={4}
              xl={4}
              key={index}
            >
              <PlanMediaCard
                title={plan.name}
                description={plan.description}
                image={`data:image/webp;base64,${
                  plan.image_promotion ? plan.image_promotion : plan.image
                }`}
                price={plan.price}
                type={plan.type}
              />
            </Grid>
          ))}
        </Grid>
        <PlanTypes />
      </Container>
    </MKBox>
  );
}

export default PlanHome;
