// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import bgImage from "assets/plus/home_bg.png";
import PlanesMid from "./sections/PlanesMid";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPlansByType } from "controllers/PlansController";
import { Box, Container, Grid, Skeleton } from "@mui/material";
import { getPlanTypes } from "controllers/PlansController";

function PlanesComponent() {
  const { id } = useParams();
  const [plans, setPlans] = useState(null);
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    setPlans(null);
    const getPlans = async () => {
      try {
        const data = await getPlansByType(id);
        setPlans(data);
      } catch (error) {
        console.error(error);
      }
    };
    const getTypes = async () => {
      try {
        const data = await getPlanTypes();
        setPlan(data.filter((p) => p.type == id));
      } catch (error) {
        console.error(error);
      }
    };
    getTypes();
    getPlans();
  }, [id]);
  return (
    <>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "overlay",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            {plan.length > 0 ? plan[0].name : ""}
          </MKTypography>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Container sx={{ mt: 2 }}>
            <Grid container item xs={12} lg={6}>
              <MKTypography variant="h3" mb={3}>
                Selecciona un Plan
              </MKTypography>
            </Grid>
            <MKTypography
              variant="h6"
              mb={6}
              color={"dark"}
              sx={{ textAlign: { xs: "justify", md: "start" } }}
            >
              {plan.length > 0 ? (
                plan[0].description
              ) : (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              )}
            </MKTypography>
          </Container>
          {plans ? (
            <>
              <PlanesMid plans={plans} />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container mx={5}>
                <Grid item xs={12} sm={6} lg={3} md={4}>
                  <Skeleton variant="rectangular" sx={{ width: 300, height: 500, m: 2 }} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} md={4}>
                  <Skeleton variant="rectangular" sx={{ width: 300, height: 500, m: 2 }} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} md={4}>
                  <Skeleton variant="rectangular" sx={{ width: 300, height: 500, m: 2 }} />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} md={4}>
                  <Skeleton variant="rectangular" sx={{ width: 300, height: 500, m: 2 }} />
                </Grid>
              </Grid>
            </Box>
          )}
        </Card>
      </MKBox>
    </>
  );
}

export default PlanesComponent;
