import { fetchCapacity } from "services/ImagesService";
import { fetchImages } from "services/ImagesService";
import { searchImageByType } from "services/ImagesService";

export const getImageByType = async (type) => {
  try {
    const data = {
      type: type,
    };
    const images = await searchImageByType(data);

    return images.data;
  } catch (error) {
    console.error("Error fetching images:", error);
    throw error;
  }
};
export const getAllImages = async () => {
  try {
    const images = await fetchImages();

    return images.data;
  } catch (error) {
    console.error("Error fetching images:", error);
    throw error;
  }
};

export const getCapacityPerYear = async () => {
  try {
    const images = await fetchCapacity();

    return images.data;
  } catch (error) {
    console.error("Error fetching images:", error);
    throw error;
  }
};
