import { Box, Card, Grid, Typography } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { TitleUnderlineComponent } from "./components/Title";
// Image
import bgImage from "assets/plus/home_bg.png";
import imageFamily from "assets/plus/parentsguide.jpg";
import hacker from "assets/plus/hacker.png";
import {
  Dashboard,
  ManageAccounts,
  MonochromePhotos,
  PersonAdd,
  Visibility,
} from "@mui/icons-material";
export const InternetSecurityGuide = () => {
  return (
    <>
      <MKBox
        minHeight="25rem"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "overlay",
          display: "grid",
          placeItems: "center",
          textAlign: "center",
        }}
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          Guía de Seguridad y Control de Internet
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          textAlign: { xs: "justify", md: "start" },
        }}
      >
        <Box p={6}>
          <TitleUnderlineComponent title={"Es importante saber que..."} />
          <Grid container justifyContent="flex-start" width={"100%"}>
            <Grid item xs={12} md={6}>
              <Box my={3} variant="body2">
                <Typography variant="body2">
                  Los niños niñas y adolescentes son un bien preciado que hay que resguardar. El
                  aumento de la tecnología ha traído como consecuencia que el uso del internet y la
                  información que en el se encuentra sea totalmente accesible para todos, siendo
                  utilizada con fines de investigación o entretenimiento. De la misma manera este
                  desarrollo ha permitido que la comunicación sea mundial y en tiempo real, por lo
                  que ahora nos encontramos conectados y llegamos a cualquier parte del mundo con un
                  simple mensaje o redes sociales.
                </Typography>
                <Typography variant="body2">
                  {" "}
                  Este auge mundial de la navegación libre por internet también representa una gran
                  responsabilidad para los padres, que con el desarrollo y la tecnología moderna
                  deben adaptarse a toda esta innovación con el fin de educar y tomar las medidas de
                  seguridad necesarias para el correcto uso de esta herramienta que al momento es
                  indispensable en diferentes ámbitos de la vida cotidiana.
                </Typography>
                <Typography variant="body2">
                  {" "}
                  Es imperativo que como padres se tengan las reglas claras por las cuales nos vamos
                  a regir en nuestro hogar, socializarlas y acordarlas con nuestros hijos.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={imageFamily}
                alt={"Guide"}
                borderRadius="lg"
                shadow="md"
                width="100%"
                position="relative"
                p={2}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" width={"100%"}>
            <Grid item xs={12} md={6}>
              <TitleUnderlineComponent title={"1.) Fomenta el uso responsable"} />
              <Box my={2} px={1} variant="body2">
                <Typography variant="body2">
                  Es recomendable, que el computador o los dispositivos para el uso de internet se
                  encuentren en una habitación a la vista de los padres, esto con el fin de que los
                  niños estén solos y evitar su participación en conversaciones con desconocidos.
                </Typography>
                <Typography variant="body2">
                  De la misma manera debemos conocer las contraseñas de todos los dispositivos y
                  redes sociales de nuestros hijos especialmente de los más jóvenes, sin invadir su
                  privacidad, a menos de que sea estrictamente necesario y bajo su conocimiento.
                </Typography>
                <Typography variant="body2">
                  Debemos educarles e informarles que todo lo que se encuentre en la red será
                  accesible para cualquier persona una vez publicado, en internet o redes sociales.
                  Así mismo debemos conocer los peligros a los que se encuentra expuestos y
                  explicarles.
                </Typography>
              </Box>
              <TitleUnderlineComponent title={"2.) Los Limites son importantes"} />
              <Typography my={2} px={1} variant="body2">
                Establece límites de uso de dispositivos electrónicos en tu hogar. Esto creará
                buenos hábitos en tus hijos y aprenderán el uso responsable del internet. La
                negociación es un buen medio para regular horarios. Es recomendable que marques las
                horas en las que tu hijo podrá hacer uso del internet. No es recomendable el uso de
                internet antes de acostarse pues puede dificultar la conciliación del sueño
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TitleUnderlineComponent title={"3.) Los riesgos son evitables. Control Parental"} />
              <Box my={2} px={1} variant="body2">
                <Typography variant="body2">
                  Utiliza la tecnología actual para evitar riesgos. Instala herramientas como
                  control parental en tus dispositivos y en los de tus hijos, adaptados a sus
                  necesidades y edad.
                </Typography>
                <Typography variant="body2">
                  Estos filtros parentales permiten controlar el acceso a contenidos indeseados,
                  establecer tiempos de uso, monitorizar la localización de tus hijos y una
                  infinidad de actividades que nos permitirán tener un mayor control sobre la
                  protección de nuestros hijos.
                </Typography>
              </Box>
              <TitleUnderlineComponent title={"4.) Interactúa con ellos"} />
              <Typography my={2} px={1} variant="body2">
                Es importante pasar tiempo de calidad con nuestros hijos. Inmiscúyete en sus
                actividades, jugar con ellos es el camino para integrarte en su mundo, conocerse y
                que ellos sientan que les dedicas tiempo mejorando así el vínculo materno/paterno
                filial.
              </Typography>
              <TitleUnderlineComponent title={"5.) Se un buen ejemplo"} />
              <Typography my={2} px={1} variant="body2">
                Recuerda que tu comportamiento es el mayor referente para tus hijos. Los niños son
                el reflejo de las costumbres de sus padres por lo cual predica con el ejemplo.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={6}>
          <TitleUnderlineComponent title={"¿De qué debemos protegernos?"} />

          <Grid container justifyContent="flex-start" width={"100%"} spacing={3}>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  p: 4,
                  borderRadius: "20px",
                  background: "#f3f3f3",
                  boxShadow: "inset 20px 20px 60px #f5f5f5, inset -20px -20px 60px #ffffff",
                }}
              >
                <TitleUnderlineComponent title={"Malware"} />
                <Typography my={2} px={1} variant="body2">
                  Es la abreviatura de los términos malicioso y software, en otras palabras, códigos
                  maliciosos. Los gusanos, troyanos o virus son ejemplos conocidos de ataques a
                  usuarios. Se pueden transmitir por diferentes medios uno de ellos son las redes
                  sociales, usando mensajes engañosos, haciendo que los equipos de los usuarios
                  puedan ser controlados remotamente por los atacantes.
                </Typography>
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  p: 4,
                  borderRadius: "20px",
                  background: "#f3f3f3",
                  boxShadow: "inset 20px 20px 60px #f5f5f5, inset -20px -20px 60px #ffffff",
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent title={"Phishing"} />
                <Typography my={2} px={1} variant="body2">
                  Es otro ataque para el robo de información confidencial. Esto se puede realizar
                  usando réplicas de páginas web, o redes sociales difícilmente identificables, lo
                  cual podría causar que los menores sean engañados e inserten información sin darse
                  cuenta
                </Typography>
              </Box>
            </Grid>

            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  p: 4,
                  borderRadius: "20px",
                  background: "#f3f3f3",
                  boxShadow: "inset 20px 20px 60px #f5f5f5, inset -20px -20px 60px #ffffff",
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent title={"Robo de identidad"} />
                <Box my={2} px={1} variant="body2">
                  <Typography variant="body2">
                    Ten precaución de que tus hijos no publiquen información personal en sus redes
                    sociales, como dirección de domicilio, teléfonos o el colegio donde estudian, ya
                    que los cybercriminale pueden usar esta información con propósitos maliciosos.
                  </Typography>
                  <Typography variant="body2">
                    El atacante usa ingeniería social para obtener la información, fingiendo ser
                    amigo de tu hijo, o una persona de la misma edad con los mismos intereses.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              mt={2}
              item
              xs={12}
              md={6}
              lg={4}
              sx={{ display: { xs: "none", md: "none", lg: "block" } }}
            >
              <Box
                sx={{
                  p: 4,
                  borderRadius: "20px",
                  background: "#f3f3f3",
                  boxShadow: "inset 20px 20px 60px #f5f5f5, inset -20px -20px 60px #ffffff",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MKBox
                  component="img"
                  src={hacker}
                  alt={"Pagos deshabilitados"}
                  width="20vh"
                  maxWidth={500}
                />
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  p: 4,
                  borderRadius: "20px",
                  background: "#f3f3f3",
                  boxShadow: "inset 20px 20px 60px #f5f5f5, inset -20px -20px 60px #ffffff",
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent title={"Cyberbullying"} />
                <Typography my={2} px={1} variant="body2">
                  Los cibercriminales no son la única amenaza para tus hijos. Sus amigos o
                  compañeros también pueden ser el problema. El bulling ya no es un problema solo de
                  clase si no que ahora se da también en la web siendo mucho más peligroso que
                  antes.
                </Typography>
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  p: 4,
                  borderRadius: "20px",
                  background: "#f3f3f3",
                  boxShadow: "inset 20px 20px 60px #f5f5f5, inset -20px -20px 60px #ffffff",
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent title={"Grooming"} />
                <Typography my={2} px={1} variant="body2">
                  Es un riesgo, orientado a los niños más jóvenes. Se trata de un adulto que finge
                  ser niño para convencerle de realizar actividades sexuales.
                </Typography>
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  p: 4,
                  borderRadius: "20px",
                  background: "#f3f3f3",
                  boxShadow: "inset 20px 20px 60px #f5f5f5, inset -20px -20px 60px #ffffff",
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent title={"Sexting"} />
                <Typography my={2} px={1} variant="body2">
                  El ‘sexting’ viene de las palabras Sexo y Texting. Se conoce como el intercambio
                  de mensajes eróticos mediante mensajes de texto. Al momento con la evolución el
                  internet también se pueden intercambiar fotos o videos.
                </Typography>
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  p: 4,
                  borderRadius: "20px",
                  background: "#f3f3f3",
                  boxShadow: "inset 20px 20px 60px #f5f5f5, inset -20px -20px 60px #ffffff",
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent title={"Spam"} />
                <Typography my={2} px={1} variant="body2">
                  Son mensajes que llegan a tu correo electrónico con contenido malicioso.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box p={6}>
          <TitleUnderlineComponent title={"5 consejos más para padres"} />
          <Grid container justifyContent="flex-start" width={"100%"} spacing={2}>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  borderRadius: "30px",
                  background: " linear-gradient(145deg, #e5e5e5, #f0f0f0)",
                  boxShadow: "20px 20px 60px #bebebe,-20px -20px 60px #ffffff",
                  p: 3,
                }}
              >
                <TitleUnderlineComponent title={"01. CREAR"} icon={<PersonAdd sx={{ mr: 1 }} />} />
                <Typography my={2} px={1} variant="body2">
                  Crea una cuenta de usuario a tu hijo. Esto con el fin de controlar su actividad en
                  la web.
                </Typography>
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  borderRadius: "30px",
                  background: " linear-gradient(145deg, #e5e5e5, #f0f0f0)",
                  boxShadow: "20px 20px 60px #bebebe,-20px -20px 60px #ffffff",
                  p: 3,
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent
                  title={"02. ACTUALIZAR"}
                  icon={<Dashboard sx={{ mr: 1 }} />}
                />
                <Typography my={2} px={1} variant="body2">
                  Actualiza tu antivirus y herramientas de control parental.
                </Typography>
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  borderRadius: "30px",
                  background: " linear-gradient(145deg, #e5e5e5, #f0f0f0)",
                  boxShadow: "20px 20px 60px #bebebe,-20px -20px 60px #ffffff",
                  p: 3,
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent
                  title={"03. VIGILAR"}
                  icon={<Visibility sx={{ mr: 1 }} />}
                />
                <Typography my={2} px={1} variant="body2">
                  Vigila su historial de navegación.
                </Typography>
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  borderRadius: "30px",
                  background: " linear-gradient(145deg, #e5e5e5, #f0f0f0)",
                  boxShadow: "20px 20px 60px #bebebe,-20px -20px 60px #ffffff",
                  p: 3,
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent
                  title={"04. CONTROLAR"}
                  icon={<MonochromePhotos sx={{ mr: 1 }} />}
                />
                <Typography my={2} px={1} variant="body2">
                  Controla la cámara web y asegúrate de que esta desconectada o cubierta.
                </Typography>
              </Box>
            </Grid>
            <Grid mt={2} item xs={12} md={6} lg={4}>
              <Box
                sx={{
                  borderRadius: "30px",
                  background: " linear-gradient(145deg, #e5e5e5, #f0f0f0)",
                  boxShadow: "20px 20px 60px #bebebe,-20px -20px 60px #ffffff",
                  p: 3,
                  height: "100%",
                }}
              >
                <TitleUnderlineComponent
                  title={"05. MANTENER"}
                  icon={<ManageAccounts sx={{ mr: 1 }} />}
                />
                <Typography my={2} px={1} variant="body2">
                  Mantén la actividad de redes sociales de tu hijo en privada. Tener mucha
                  información pública puede implicar un riesgo.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};
