import React from "react";

// eslint-disable-next-line react/prop-types
export default function CarouselItem({ imgUrl, imgTitle }) {
  return (
    <div className="carousel-card">
      <img src={`data:image/webp;base64,${imgUrl}`} alt={imgTitle} loading="lazy"></img>
    </div>
  );
}
