import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import PropTypes from "prop-types";

const CarouselComponent = ({
  images,
  height = "500px",
  interval = 3000,
  controls = true,
  style = {},
}) => {
  const [activeIndex, setActiveIndex] = useState(0); // Estado para seguir el índice activo

  // Cambiar el índice activo del carrusel
  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <>
      {images.length > 0 ? (
        <div style={{ ...styles.carouselContainer, height, ...style }}>
          {/* Fondo con imagen difusa */}
          <div
            style={{
              ...styles.backgroundImage,
              backgroundImage: `url(data:image/webp;base64,${images[activeIndex].file_data})`, // Fondo con la imagen actual
            }}
          ></div>

          {/* Carrusel */}
          <Carousel controls={controls} activeIndex={activeIndex} onSelect={handleSelect}>
            {images.map((img, index) => (
              <Carousel.Item key={index} interval={interval}>
                <div style={{ ...styles.imageContainer, height }}>
                  <img
                    className="d-block w-100 h-100"
                    style={styles.image}
                    src={`data:image/webp;base64,${img.file_data}`}
                    alt={`Slide ${index + 1}`}
                    loading="lazy"
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      ) : null}
    </>
  );
};

const styles = {
  carouselContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    // La altura se pasa como una propiedad y se aplica en línea.
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover", // La imagen cubre todo el fondo
    backgroundPosition: "center", // Centra la imagen de fondo
    backgroundRepeat: "no-repeat", // Evita que se repita
    filter: "blur(2px)", // Aplica el blur solo al fondo
    zIndex: -1, // Asegura que el fondo esté detrás del contenido del carrusel
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    zIndex: 1, // Las imágenes del carrusel están por encima del fondo
  },
  image: {
    maxHeight: "100%",
    objectFit: "contain", // Mantiene las proporciones de la imagen
  },
};

CarouselComponent.propTypes = {
  images: PropTypes.array.isRequired,
  height: PropTypes.string, // Permite pasar una altura dinámica
  interval: PropTypes.number, // Permite pasar una altura dinámica
  controls: PropTypes.bool, // Permite pasar una altura dinámica
  style: PropTypes.object,
};

export default CarouselComponent;
