import { Box, Card, Grid, Typography } from "@mui/material";
import { TitleUnderlineComponent } from "pages/LandingPages/OtherPages/components/Title";
import { LocationOn } from "@mui/icons-material";
// eslint-disable-next-line react/prop-types
export const SucursalesMap = ({ map }) => {
  return (
    <Card
      sx={{
        my: 4,
        borderRadius: 0,
        p: 3,
        mx: -3,
        background: "linear-gradient(145deg,rgb(255, 255, 255), #ffffff)",
        boxShadow: "13px 13px 26px #d9d9d9, -13px -13px 26px #ffffff",
      }}
    >
      <TitleUnderlineComponent variant={"h3"} title={"PLUS en Ecuador"} align={"center"} />
      <Grid container my={3}>
        <Grid item xs={12} md={6}>
          <Box p={2} display={"flex"} justifyContent={"center"}>
            <img
              src={`data:image/webp;base64,${map}`}
              alt="Plus Ecuador"
              style={{ width: "100%", maxWidth: "550px" }}
              loading="lazy"
            />
          </Box>
        </Grid>
        <Grid item container xs={12} justifyContent={"center"} md={6}>
          <Grid md={6} item>
            <Box display="flex" alignItems="center">
              <LocationOn fontSize="medium" sx={{ color: "rgb(26, 115, 232)" }} />
              <TitleUnderlineComponent variant={"h5"} title={"Carchi"} pb={0} />
            </Box>
            <Box mb={2}>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Bolívar
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                El Carmelo
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Julio Andrade
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Mira
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                San Isidro
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationOn fontSize="medium" sx={{ color: "rgb(26, 115, 232)" }} />
              <TitleUnderlineComponent variant={"h5"} title={"Imbabura"} pb={0} />
            </Box>
            <Box mb={2}>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Ibarra
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Urcuquí
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Atuntaqui
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Cotacachi
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Otavalo
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Pimampiro
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Lita
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Eugenio Espejo
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Buenos Aires
              </Typography>
            </Box>
          </Grid>
          <Grid md={6} item>
            <Box display="flex" alignItems="center">
              <LocationOn fontSize="medium" sx={{ color: "rgb(26, 115, 232)" }} />
              <TitleUnderlineComponent variant={"h5"} title={"Pichincha"} pb={0} />
            </Box>
            <Box mb={2}>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Cayambe
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                El Quinche
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Quito Norte
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Quito Sur
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationOn fontSize="medium" sx={{ color: "rgb(26, 115, 232)" }} />
              <TitleUnderlineComponent variant={"h5"} title={"Guayas"} pb={0} />
            </Box>
            <Box mb={2}>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Guayaquil
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationOn fontSize="medium" sx={{ color: "rgb(26, 115, 232)" }} />
              <TitleUnderlineComponent variant={"h5"} title={"Chimborazo"} pb={0} />
            </Box>
            <Box mb={2}>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Riobamba
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Guano
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationOn fontSize="medium" sx={{ color: "rgb(26, 115, 232)" }} />
              <TitleUnderlineComponent variant={"h5"} title={"Sucumbios"} pb={0} />
            </Box>
            <Box mb={2}>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Santa Bárbara
              </Typography>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                El Playón
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationOn fontSize="medium" sx={{ color: "rgb(26, 115, 232)" }} />
              <TitleUnderlineComponent variant={"h5"} title={"Azuay"} pb={0} />
            </Box>
            <Box mb={2}>
              <Typography sx={{ color: "rgb(60, 135, 234)" }} ml={5} fontSize={"medium"}>
                Ponce Enríquez
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
