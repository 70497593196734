import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Card, IconButton, Backdrop, Fade } from "@mui/material";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "90%", md: "30%" },
  boxShadow: 24,
  m: 0,
};

const ModalPromotional = ({ open, handleClose, image }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      sx={{ p: 0 }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Card>
            <IconButton
              onClick={handleClose}
              title="Cerrar"
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                bgcolor: "rgba(255, 255, 255, 0.81)",
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.67)",
                },
                zIndex: 10,
              }}
            >
              <Close />
            </IconButton>
            <Box>
              <img
                src={`data:image/webp;base64,${image.file_data}`}
                alt="Promocional"
                style={{ width: "100%" }}
                loading="lazy"
              />
            </Box>
          </Card>
        </Box>
      </Fade>
    </Modal>
  );
};

ModalPromotional.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  image: PropTypes.any,
};

export default ModalPromotional;
