import axios from "axios";

const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_URL, // Base de la API
  timeout: 10000, // Tiempo máximo de espera para una solicitud (opcional)
  headers: {
    // eslint-disable-next-line no-undef
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN_BACKEND}`,
    "Content-Type": "application/json",
  },
});

export default api;
