// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

//
import plusLogo from "assets/plus/plus_navbar.webp";
import { Call, Email, Facebook, WhatsApp } from "@mui/icons-material";
import { Icon } from "@mui/material";

function DefaultFooter({ content, social, contacts }) {
  const { brand, menus } = content;

  return (
    <MKBox component="footer" sx={{ backgroundColor: "#00265f", pt: 10 }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox
                  component="img"
                  src={plusLogo}
                  alt={brand.name}
                  maxWidth="15rem"
                  opacity={0.8}
                  mb={1}
                  sx={{ filter: "grayscale(100%)" }}
                />
              </Link>
              <MKTypography color={"white"} opacity={0.8} variant="h6">
                {brand.name}
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={1}>
              {social.map(({ url, icon }, key) => (
                <MKTypography
                  key={url}
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="white"
                  opacity={0.8}
                  mr={key === social.length - 1 ? 0 : 2.5}
                >
                  {icon == "facebook" ? (
                    <>
                      <Facebook />
                    </>
                  ) : icon == "whatsapp" ? (
                    <>
                      <WhatsApp />
                    </>
                  ) : icon == "instagram" ? (
                    <>
                      <InstagramIcon />
                    </>
                  ) : icon == "tiktok" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-tiktok"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                      </svg>
                    </>
                  ) : (
                    <Icon>{icon}</Icon>
                  )}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={12} md={2} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
                color="white"
                opacity={0.8}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {title == "Contáctanos" ? (
                  <>
                    {contacts.map((contact, index) => (
                      <div key={index}>
                        <MKTypography
                          fontWeight="bold"
                          variant="button"
                          textTransform="capitalize"
                          color="white"
                          opacity={0.85}
                        >
                          {contact.name}
                        </MKTypography>
                        <MKTypography
                          display="block"
                          fontWeight="regular"
                          variant="button"
                          textTransform="capitalize"
                          color="white"
                          opacity={0.7}
                          component="a"
                          href={`tel:${contact.phone1}`}
                        >
                          <Call /> {contact.phone1}
                        </MKTypography>
                        {contact.phone2 ? (
                          <MKTypography
                            display="block"
                            fontWeight="regular"
                            variant="button"
                            textTransform="capitalize"
                            color="white"
                            opacity={0.7}
                            component="a"
                            href={`tel:${contact.phone2}`}
                          >
                            <Call /> {contact.phone2}
                          </MKTypography>
                        ) : null}
                        {contact.email1 ? (
                          <MKTypography
                            display="block"
                            fontWeight="regular"
                            variant="button"
                            color="white"
                            opacity={0.7}
                            component="a"
                            href={`mailto:${contact.email1}`}
                          >
                            <Email /> {contact.email1}
                          </MKTypography>
                        ) : null}
                        {contact.email2 ? (
                          <MKTypography
                            display="block"
                            fontWeight="regular"
                            variant="button"
                            color="white"
                            opacity={0.7}
                            component="a"
                            href={`mailto:${contact.emai12}`}
                          >
                            <Email /> {contact.email2}
                          </MKTypography>
                        ) : null}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {items.map(({ name, route, href, icon }) => (
                      <MKBox
                        key={name}
                        component="li"
                        p={0}
                        m={0}
                        lineHeight={1.25}
                        sx={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}
                      >
                        {href ? (
                          <MKTypography
                            component="a"
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            color="white"
                            opacity={0.7}
                          >
                            {icon ? <Icon>{icon}</Icon> : null} {name}
                          </MKTypography>
                        ) : (
                          <MKTypography
                            component={Link}
                            to={route}
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            color="white"
                            opacity={0.7}
                          >
                            {name}
                          </MKTypography>
                        )}
                      </MKBox>
                    ))}
                  </>
                )}
              </MKBox>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sx={{ textAlign: "center", my: 3, color: "white !important" }}
            fontSize={12}
          >
            © PLUS 2024 TODOS LOS DERECHOS RESERVADOS
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
  social: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
};

export default DefaultFooter;
