// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Collapse,
  Icon,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import FadeInComponent from "components/FadeInCompontent";
import { useState } from "react";
import { ExpandMore, ExpandMoreOutlined } from "@mui/icons-material";

function DefaultReviewCard({ color, image, name, review, type, title = false }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <FadeInComponent style={{ display: "flex", justifyContent: "center" }}>
      <Card
        sx={{
          backgroundColor: "#121162",
          maxWidth: "99%",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            maxWidth: "100%",
          },
        }}
        color={color}
        borderRadius="xl"
        shadow={"md"}
        p={0}
      >
        {image && (
          <CardActionArea href={`/pages/planes/${type}`}>
            <CardMedia
              sx={{
                m: 0,
                borderEndEndRadius: 0,
                borderEndStartRadius: 0,
                maxWidth: 395,
                width: "100%",
              }}
              component="img"
              alt={name}
              image={`data:image/webp;base64,${image}`}
            />
          </CardActionArea>
        )}
        {title && (
          <CardContent sx={{ py: 0 }}>
            <Typography
              display="block"
              align="center"
              variant={image ? "button" : "h6"}
              fontWeight="bold"
              m={0}
              sx={{
                borderBottom: expanded ? "1px solid rgba(255,255,255, 0.4)" : "0px",
                color: "white !important",
              }}
              py={1}
            >
              {name}
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreOutlined />
              </ExpandMore>
            </Typography>
          </CardContent>
        )}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography
            my={4}
            fontSize={"small"}
            px={3}
            sx={{
              textAlign: "center",
              color: "rgba(255,255,255, 0.8) !important",
            }}
          >
            {review}
          </Typography>
        </Collapse>

        <Link to={`/pages/planes/${type}`}>
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              color: "white !important",
              backgroundColor: "rgba(0, 47, 255, 0.17) !important",
              fontWeight: "bolder !important",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderEndEndRadius: 10,
              borderEndStartRadius: 10,
              "&:hover": {
                opacity: 1,
                backgroundColor: "rgba(6, 79, 163, 0.6) !important",
              },
              "& .material-icons, .material-icons-round,": {
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },

              "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
                {
                  transform: `translateX(6px)`,
                },
            }}
          >
            Ver Planes
            <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_forward</Icon>
          </Button>
        </Link>
      </Card>
    </FadeInComponent>
  );
}

// Setting default values for the props of DefaultReviewCard
DefaultReviewCard.defaultProps = {
  color: "transparent",
  image: "",
};

// Typechecking props for the DefaultReviewCard
DefaultReviewCard.propTypes = {
  color: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  review: PropTypes.string,
  type: PropTypes.string,
  rating: PropTypes.oneOf([1, 2, 3, 4, 5]),
  title: PropTypes.bool,
};

export default DefaultReviewCard;
