import React, { useEffect, useState } from "react";
import "./autoplaycarousel.css";
import CarouselItem from "./CarouselItem";
import { getAllClients } from "controllers/ClientsController";

export default function AutoplayCarousel() {
  const [clients, setClients] = useState([]);
  useEffect(() => {
    getAllClients().then((clients) => {
      setClients(clients);
    });
  }, []);

  return (
    <div className="carousel-container">
      {clients && (
        <>
          <div className="carousel-track carousel-right">
            {clients
              .slice(0, clients.length / 2)
              .concat(clients.slice(0, clients.length / 2))
              .map((client, index) => {
                return (
                  <CarouselItem
                    key={index}
                    imgUrl={client.logo}
                    imgTitle={client.name}
                  ></CarouselItem>
                );
              })}
            {clients
              .slice(0, clients.length / 2)
              .concat(clients.slice(0, clients.length / 2))
              .map((client, index) => {
                return (
                  <CarouselItem
                    key={index}
                    imgUrl={client.logo}
                    imgTitle={client.name}
                  ></CarouselItem>
                );
              })}
          </div>
          <div className="carousel-track carousel-left">
            {clients
              .slice(clients.length / 2, clients.length)
              .concat(clients.slice(clients.length / 2, clients.length))
              .map((client, index) => {
                return (
                  <CarouselItem
                    key={index}
                    imgUrl={client.logo}
                    imgTitle={client.name}
                  ></CarouselItem>
                );
              })}
            {clients
              .slice(clients.length / 2, clients.length)
              .concat(clients.slice(clients.length / 2, clients.length))
              .map((client, index) => {
                return (
                  <CarouselItem
                    key={index}
                    imgUrl={client.logo}
                    imgTitle={client.name}
                  ></CarouselItem>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}
