import { Box, Card } from "@mui/material";
import AutoplayCarousel from "components/Carousel/AutoplayCarousel/AutoplayCarousel";
import { TitleUnderlineComponent } from "pages/LandingPages/OtherPages/components/Title";
export const Customers = () => {
  return (
    <Card
      sx={{
        my: 4,
        borderRadius: 0,
        p: 3,
        mx: -3,
        background: "linear-gradient(145deg,rgb(255, 255, 255), #ffffff)",
        boxShadow: "13px 13px 26px #d9d9d9, -13px -13px 26px #ffffff",
      }}
    >
      <TitleUnderlineComponent title={"Clientes que confían en nosotros"} align={"center"} />
      <Box my={3}>
        <AutoplayCarousel />
      </Box>
    </Card>
  );
};
