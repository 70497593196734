// @mui material components
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Image
import bgImage from "assets/plus/home_bg.png";

import Sucursales from "./components/Sucursales";
import ContactsCard from "./components/ContactsCard";
import { APIProvider } from "@vis.gl/react-google-maps";
import { useLocation } from "react-router-dom";
import FormContacts from "./components/FormContacts";
import { useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import FadeInComponent from "components/FadeInCompontent";

function ContactUs() {
  const location = useLocation();
  const message = location.state?.message || "";
  const [loading, setLoading] = useState(true);

  return (
    <>
      <MKBox
        minHeight="25rem"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "overlay",
          display: "grid",
          placeItems: "center",
        }}
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          Contáctanos
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={3} justifyContent={"center"} alignItems="center">
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 3 }}
            mr={{ xs: "auto", lg: 3 }}
          >
            <Card sx={{ p: 3 }}>
              <MKTypography
                variant="h3"
                sx={{
                  color: "#0a0855",
                  position: "relative",
                  pb: 2,
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "2px",
                    background:
                      "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)",
                  },
                  width: "100%",
                  textAlign: "center",
                  mb: 2,
                }}
              >
                Matriz Ibarra
              </MKTypography>
              <APIProvider
                // eslint-disable-next-line no-undef
                apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
              >
                {loading ? (
                  <Stack
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: "50%",
                    }}
                  >
                    <CircularProgress color="info" size="40px" />
                  </Stack>
                ) : null}

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d249.35919214814024!2d-78.12014129895687!3d0.34240640278248197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e2a3ccf1656ee35%3A0x51033af58e908552!2sAirmax%20Telecom!5e0!3m2!1ses!2sec!4v1732284164955!5m2!1ses!2sec"
                  width="100%"
                  height="450"
                  loading="lazy"
                  onLoad={() => setLoading(false)}
                ></iframe>
              </APIProvider>
            </Card>
          </Grid>

          <Grid item xs={12} sm={10} md={7} lg={6} xl={4} mx={{ xs: "auto", lg: 3 }} my={10}>
            <FormContacts message={message} />
          </Grid>
        </Grid>
        <FadeInComponent>
          <ContactsCard />
        </FadeInComponent>
        <Sucursales />
      </Card>
    </>
  );
}

export default ContactUs;
