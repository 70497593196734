import { fetchClients } from "services/ClientsService";

export const getAllClients = async () => {
  try {
    const clients = await fetchClients();

    return clients.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
