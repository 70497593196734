import { Call, ContactPhone, Email } from "@mui/icons-material";
import { Avatar, Box, Card, CardContent, Container, Grid, Link, Typography } from "@mui/material";
import { getAllContacts } from "controllers/ContactController";
import React, { useEffect, useState } from "react";

function ContactsCard() {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const data = await getAllContacts();
        setContacts(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchContacts();
  }, []);
  return (
    <Container>
      <Card sx={{ boxShadow: "0px 10px 32px -3px rgba(0,0,0,0.1)" }}>
        <CardContent>
          <Avatar
            sx={{
              bgcolor: "#338ded",
              width: 80,
              height: 80,
              mt: -5,
              boxShadow: "0px 10px 32px -3px rgba(0,0,0,0.1)",
            }}
          >
            <ContactPhone sx={{ fontSize: 30 }} fontSize="medium" />
          </Avatar>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                position: "relative",
                pb: 2,
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "2px",
                  background:
                    "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)",
                },
              }}
              my={3}
              variant="h4"
            >
              CONTACTOS
            </Typography>
            <Grid
              display={"flex"}
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              container
              spacing={3}
            >
              {contacts.map((contact, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      position: "relative",
                      pb: 1,
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "2px",
                        background:
                          "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)",
                      },
                    }}
                  >
                    {contact.name}
                  </Typography>
                  <Box sx={{ my: 2, textAlign: "center" }}>
                    <Typography sx={{ color: "#344767" }} ml={1} fontSize={"small"}>
                      <Call /> <b>Teléfono: </b>{" "}
                      <Link href={`tel:${contact.phone1}`} underline="hover">
                        {contact.phone1}
                      </Link>
                    </Typography>
                    {contact.phone2 ? (
                      <>
                        <Typography sx={{ color: "#344767" }} ml={1} fontSize={"small"}>
                          <Call /> <b>Teléfono 2: </b>{" "}
                          <Link href={`tel:${contact.phone2}`} underline="hover">
                            {contact.phone2}
                          </Link>
                        </Typography>
                      </>
                    ) : null}
                    {contact.email1 ? (
                      <>
                        <Typography sx={{ color: "#344767" }} ml={1} fontSize={"small"}>
                          <Email /> <b>Email: </b>{" "}
                          <Link href={`mailto:${contact.email1}`} underline="hover">
                            {contact.email1}
                          </Link>
                        </Typography>
                      </>
                    ) : null}
                    {contact.email2 ? (
                      <>
                        <Typography sx={{ color: "#344767" }} ml={1} fontSize={"small"}>
                          <Email /> <b>Email 2: </b>{" "}
                          <Link href={`mailto:${contact.email2}`} underline="hover">
                            {contact.email2}
                          </Link>
                        </Typography>
                      </>
                    ) : null}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default ContactsCard;
