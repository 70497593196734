import React from "react";
import PropTypes from "prop-types";
import { Modal, Box } from "@mui/material";
import FormContacts from "pages/LandingPages/ContactUs/components/FormContacts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 150,
  boxShadow: 24,
  m: 0,
};

const ModalForm = ({ open, handleClose, message }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ p: 0 }}
    >
      <Box sx={style}>
        <FormContacts message={message} modal={true} action={handleClose} />
      </Box>
    </Modal>
  );
};

ModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default ModalForm;
