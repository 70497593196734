// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

//
import plusLogo from "assets/plus/plus_navbar.webp";
export default {
  brand: {
    name: "Encuéntranos en",
    image: plusLogo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "Menú",
      items: [
        { name: "PLUS - Internet de alta velocidad", route: "/" },
        { name: "Tarifas", route: "/pages/planes" },
        { name: "Pagos", route: "/pages/pagos" },
        { name: "Contáctanos", route: "/pages/contact-us" },
        {
          name: "Glosario de Términos",
          route: "/pages/glossary-terms",
        },
        { name: "Premios" },
        {
          name: "Nosotros",
          route: "/pages/about-us",
        },
        {
          name: "Nuestra Capacidad",
          route: "/pages/capacidad",
        },
        {
          name: "SpeedTest",
          href: "https://airmaxtelecom.speedtestcustom.com/",
        },
      ],
    },
    {
      name: "Normas - regulaciones",
      items: [
        { name: "Reglamento de abonados", route: "/pages/REGLAMENTO-ABONADOS" },
        { name: "Norma SVA", route: "/pages/NORMA-SVA" },
        { name: "Arcotel", href: "https://www.arcotel.gob.ec/" },
        { name: "Políticas de Privacidad", route: "/pages/privacity-policies" },

        { name: "Guía de Control Parental", route: "/pages/GUIA-CONTROL-PARENTAL" },
        {
          name: "Guía de Seguridad y Control de Internet",
          route: "/pages/internet-security-guide",
        },
        {
          name: "Glosario de Términos",
          route: "/pages/glossary-terms",
        },
        {
          name: "Reglamento de Adulto Mayor",
          route: "/pages/REGLAMENTO-GENERAL-LEY-ORGANICA-DE-LAS-PERSONAS-ADULTAS-MAYORES",
        },
        {
          name: "Reforma al reglamento General",
          route: "/pages/REFORMA-AL-REGLAMENTO-GENERAL",
        },
        {
          name: "Ley Adulto Mayor",
          route: "/pages/LEY-ADULTO-MAYOR",
        },
        {
          name: "Resolución Arcotel sobre Adulto Mayor",
          route: "/pages/RESOLUCION-ARCOTEL-SOBRE-ADULTO-MAYOR",
        },
        /*{
          name: "Parámetros de Calidad",
          route: "/pages/quality-parameters",
        },*/
      ],
    },
    {
      name: "Contáctanos",
      items: [],
    },
    {
      name: "Visítanos",
      items: [
        {
          name: "Oficina Matriz: Teodoro Gómez de la Torre y Calixto Miranda Esquina.",
        },
        {
          name: "Ir a Dirección ",
          href: "https://maps.app.goo.gl/3HTzrqw2Qtdv6Ceg8",
          icon: "location_on",
        },
      ],
    },
  ],
};
