import { Box, Card, Typography } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
// Image
import bgImage from "assets/plus/home_bg.png";
import { TitleUnderlineComponent } from "./components/Title";

export const PrivacyPolicies = () => {
  return (
    <>
      <MKBox
        minHeight="25rem"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(326deg, rgba(2,0,36,0.5) 0%, rgba(9,9,121,0.5) 35%, rgba(0,162,255,0.7) 100%), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "overlay",
          display: "grid",
          placeItems: "center",
        }}
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          Políticas de Privacidad
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          textAlign: { xs: "justify", md: "start" },
        }}
      >
        <Box p={6}>
          <Typography my={3} variant="body2">
            Su privacidad es de gran importancia, como parte de Plus Servicios Tecnológicos el
            objetivo principal del portal web es ofrecer información y recursos de calidad a los
            usuarios, además de mantener las políticas de confidencialidad especificadas más
            adelante, esto para proteger su información personal obtenida a través de los servicios
            en línea. Se recomienda revisar constantemente estas Políticas de Privacidad por
            posibles cambios o actualizaciones, con el fin de asegurarse que está de acuerdo con
            dichos cambios.
          </Typography>

          <TitleUnderlineComponent title={"Información que se obtiene"} />
          <Typography my={2} px={1} variant="body2">
            Nuestro sitio web podrá obtener información personal como su nombre, información de
            contacto, es decir su dirección de correo electrónica e información demográfica. De ser
            necesario se podrá requerir información específica para procesar alguna transacción en
            particular.
          </Typography>
          <TitleUnderlineComponent title={"Uso de la información recogida"} />
          <Typography my={2} px={1} variant="body2">
            La información es empleada para proporcionar un mejor servicio, particularmente para
            mantener un registro de usuarios, de pedidos, de consultas, de pagos, entre otros, y
            mejorar de esta forma nuestros productos y servicios. Probablemente sean enviados
            correos electrónicos con información que consideremos relevante para usted o que pueda
            brindarle algún beneficio. Nuestro compromiso es mantener su información segura, por lo
            cual se mantiene en constantes revisiones y actualizaciones con el fin de asegurarnos
            que no exista ningún acceso no autorizado.
          </Typography>
          <TitleUnderlineComponent title={"Confidencialidad de la Información"} />
          <Typography my={2} px={1} variant="body2">
            La información obtenida es confidencial por lo que no se compartirá a terceros, a
            excepción de que se presente la autorización de las personas que se obtuvo la
            información, o en el caso que sea requerido por orden judicial.
          </Typography>

          <TitleUnderlineComponent title={"Cookies"} />
          <Typography my={2} px={1} variant="body2">
            Las cookies son ficheros que se descargan es su dispositivo y permiten almacenar y
            recuperar información obteniendo mejor experiencia de navegación en el sitio web
            facilitando futuras visitas recurrentes. Usted puede aceptar o no el uso de cookies,
            aunque la mayoría de los navegadores aceptan su uso de forma automática, en este caso
            debe cambiar la configuración de su ordenador para negar su uso.
          </Typography>
          <TitleUnderlineComponent title={"Enlaces a Terceros"} />
          <Typography my={2} px={1} variant="body2">
            Nuestro sitio web podría contener enlaces a otros sitios, por lo cual al momento que da
            click y abandona nuestra página, ya no tenemos control sobre el sitio a donde se realizó
            la redirección y por ende no somos responsables de la protección de sus datos, o de los
            términos de privacidad especificados. Los demás sitios deben tener sus propias políticas
            de privacidad por lo cual debe revisarlas y confirmar que está de acuerdo.
          </Typography>
          <TitleUnderlineComponent title={"Aceptación de los términos"} />
          <Typography my={2} px={1} variant="body2">
            Al hacer uso de los servicios brindados por iplus.com.ec el usuario da a entender que ha
            leídos, entendido y aceptado los términos y condiciones previamente detallados, en el
            caso de no estar de acuerdo el usuario no debe hacer uso de los servicios como tal y no
            debe proporcionar ninguna información personal.
          </Typography>
        </Box>
      </Card>
    </>
  );
};
