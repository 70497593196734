// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultReviewCard from "components/Cards/ReviewCards/DefaultReviewCard";
import { useEffect, useState } from "react";
import { getPlanTypes } from "controllers/PlansController";

function PlanTypes() {
  const [types, setTypes] = useState(null);

  const getTypes = async () => {
    try {
      const data = await getPlanTypes();
      setTypes(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTypes();
  }, []);
  return (
    <MKBox component="section" py={3}>
      <Container
        sx={{ p: "0px !important", width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Grid container spacing={3} sx={{ mt: 4, p: "0px !important", width: "100%" }}>
          {types && types.length > 0
            ? types.map((type, index) => (
                <Grid
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  key={index}
                  item
                  xs={12}
                  sm={12} // Si solo hay 1, ocupa toda la fila
                  md={types.length <= 2 ? 6 : 4} // Ajusta para 2 o más elementos
                  lg={types.length >= 3 ? 4 : 6} // Ajusta para 3 o más elementos
                >
                  <DefaultReviewCard
                    color={
                      type.type === "fibra"
                        ? "rgb(26, 135, 202)"
                        : type.type === "wireless"
                        ? "rgb(122, 83, 156)"
                        : "rgb(67, 111, 129)"
                    }
                    name={type.name}
                    review={type.description}
                    rating={5}
                    type={type.type}
                    image={type.image}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PlanTypes;
