// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import PlanMediaCard from "components/Cards/MediaCard";
import PropTypes from "prop-types";
import { useState } from "react";
import ModalForm from "components/Modals/ModalForm";

// Material Kit 2 React components

function PlanesMid({ plans }) {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const handleOpen = (m) => {
    setMsg(m);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <MKBox component="section" py={2}>
      <ModalForm open={open} handleClose={handleClose} message={msg} />
      <Container>
        <Grid container spacing={3}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} lg={3} md={4} key={index}>
              <PlanMediaCard
                title={plan.name}
                description={plan.description}
                image={`data:image/webp;base64,${
                  plan.image_promotion ? plan.image_promotion : plan.image
                }`}
                price={plan.price}
                type={plan.type}
                action={handleOpen}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}
PlanesMid.propTypes = {
  plans: PropTypes.array.isRequired,
};

export default PlanesMid;
