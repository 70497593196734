import { Alert, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import TableDataPayment from "./TableDataPayment";
import TableDataPaymentMade from "./TableDataPaymentMade";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PaymentInfo = ({ data }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box p={3}>
      <Typography align="center" variant="h3" m={2}>
        Datos del Usuario
      </Typography>
      <Box>
        <Typography variant="subtitle2">
          <b>Usuario:</b> {data.infoLogin.Usuario}
        </Typography>
        <Typography variant="subtitle2">
          <b>Nombre:</b> {data.infoLogin.Nombres}
        </Typography>
        <Typography variant="subtitle2">
          <b>Dirección:</b> {data.infoLogin.Direccion}
        </Typography>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">
              <b>Teléfono:</b> {data.infoLogin.Telefono ? data.infoLogin.Telefono : ""}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">
              <b>Correo:</b> {data.infoLogin.Correo ? data.infoLogin.Correo : ""}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box my={3}>
        <Alert variant="standard" severity="error">
          <b>Nota:</b> Si tiene más de un contrato, realice primero el pago del contrato{" "}
          <b>#{data.infoLogin.Id}</b>. Luego, inicie sesión nuevamente con sus datos para cancelar
          el otro u otros contratos restantes.
        </Alert>
      </Box>
      <Box my={3}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab sx={{ fontWeight: "bold" }} label="Pagos Pendientes" {...a11yProps(0)} />
          <Tab sx={{ fontWeight: "bold" }} label="Pagos Realizados" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <TableDataPayment data={data.infoPayment} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TableDataPaymentMade />
      </CustomTabPanel>
    </Box>
  );
};

PaymentInfo.propTypes = {
  data: PropTypes.object,
};

export default PaymentInfo;
