// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-countup component
import CountUp from "react-countup";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Box } from "@mui/material";

function DefaultCounterCard({ color, count, title, description, image, ...rest }) {
  return (
    <MKBox p={2} textAlign="center" lineHeight={1}>
      {count ? (
        <MKTypography variant="h1" color={color} textGradient>
          <CountUp end={count} duration={1} {...rest} />
        </MKTypography>
      ) : image ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2, maxHeight: "80px" }}>
          <img
            src={`data:image/webp;base64,${image}`}
            alt={title}
            style={{
              width: "auto", // Para que el ancho se ajuste proporcionalmente
              height: "100%", // Ocupa toda la altura del contenedor
              maxHeight: "80px",
              maxWidth: "100%",
              objectFit: "contain", // Cambia a "cover" si prefieres que la imagen llene el contenedor
            }}
            loading="lazy"
          />
        </Box>
      ) : null}
      {title && (
        <MKTypography variant="h5" mt={2} mb={1}>
          {title}
        </MKTypography>
      )}
      {description && (
        <MKTypography variant="body2" color="text">
          {description}
        </MKTypography>
      )}
    </MKBox>
  );
}

// Setting default props for the DefaultCounterCard
DefaultCounterCard.defaultProps = {
  color: "info",
  description: "",
  title: "",
};

// Typechecking props for the DefaultCounterCard
DefaultCounterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  count: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default DefaultCounterCard;
