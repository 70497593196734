/* eslint-disable react/prop-types */
import { Typography } from "@mui/material";
import React from "react";

export const TitleUnderlineComponent = ({
  title,
  icon,
  align = "left",
  pb = 2,
  variant = "h4",
}) => {
  return (
    <Typography
      variant={variant}
      sx={{
        textAlign: align,
        position: "relative",
        color: "rgb(26, 115, 232)",
        pb,
        width: "100%",
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "2px",
          background:
            align == "left"
              ? "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,1) 96%)"
              : align == "center"
              ? "linear-gradient(326deg, rgba(80,14,93,0) 12%, rgb(26, 115, 232) 49%, rgba(75,189,255,0) 96%)"
              : "",
        },
      }}
    >
      {icon ? icon : null}
      {title}
    </Typography>
  );
};
